<template>
<div>
<div :style="'display: inline-flex; border: 1px solid #888; padding: 0; width: '+(width+1)+'px; height: '+(height)+'px;'">
    
	<progress-bar style='margin-top: -2px; margin-left: 0px;'
      :options="(percentage>=101) ? PBOptionsRed: (percentage<=treshold) ? PBOptionsYellow: PBOptions"
      :value="Math.round(100*percentage)/100"
      />
</div>
</div>
</template>
<script>

export default {
  props: {
  	percentage: { type: Number, default: 0.0 },
  	width: { type: Number, default: 83 },
  	height: { type: Number, default: 18 },
  	treshold: { type: Number, default: 50 }
  },
 components : {
    
  },
  data () {
    return {
      PBOptions: {
		  text: {
			color: '#000',
			shadowEnable: false,
		    fontSize: 9,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#6d6',
		    backgroundColor: '#ddd'
		  },
		  layout: {
		    height: this.height,
		    width: this.width,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 33,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 },
		 PBOptionsRed: {
		  text: {
			color: '#eee',
			shadowEnable: false,
		    fontSize: 9,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#f66',
		    backgroundColor: '#ddd'
		  },
		  layout: {
			height: this.height,
		    width: this.width,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 33,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 },
		 PBOptionsYellow: {
			  text: {
				color: '#000',
				shadowEnable: false,
			    fontSize: 9,
			    fontFamily: 'Helvetica',
			    dynamicPosition: false,
			    hideText: false
			  },
			  progress: {
			    color: '#ff6',
			    backgroundColor: '#ddd'
			  },
			  layout: {
				height: this.height,
			    width: this.width,
			    verticalTextAlign: 71,
			    horizontalTextAlign: 33,
			    zeroOffset: 0,
			    strokeWidth: 0,
			    progressPadding: 0,
			    type: 'line'
			  }
			 }
    }
  }
 }
</script>
<style lang="scss" scoped>

</style>